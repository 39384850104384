import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationName',
  standalone: true,
})
export class LocationNamePipe implements PipeTransform {
  private excludedNames: string[] = [
    'Health',
    'Quest',
    'Portal',
    'Mid',
    'Atlantic',
    'Mid-Atlantic',
    '-',
    'Your',
    'Organization:',
  ];

  transform(value: string): string {
    if (!value) return '';
    return value
      .split(' ')
      .filter((word) => !this.excludedNames.includes(word))
      .join(' ');
  }
}
